body,
html {
  margin: 0;
  font-family: "Pretendard-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular";
  font-weight: 400;
  word-break: keep-all;
}

.gmarket {
  font-family: "GmarketSansMedium";
}

:root {
  --background-color: white;
  --light-color: #f7f7f7;
  --gray-color: #e7e7e7;
  --point-color: #3660fa;
  --font-color: #1c1c1c;

  --header-height: 100px;
  --container-width: 1360px;
  --container: 88%;
  --input-height: 65px;

  --font-size-default: 18px;
  --f16: 16px;
  --f20: 20px;
  --f24: 24px;
  --f28: 28px;
  --f42: 42px;
  --f55: 55px;
}

html {
  font-size: var(--font-size-default);
}

p {
  font-size: var(--font-size-default);
  line-height: 1.6;
}

h1 {
  font-size: var(--f55);
  font-weight: bolder;
}

h2 {
  font-size: var(--f42);
  font-weight: bolder;
}

h3 {
  font-size: var(--f28);
  font-weight: bolder;
}

h4 {
  font-size: var(--f24);
  font-weight: bold;
}

h5 {
  font-size: var(--f20);
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  position: relative;
  width: 100%;
  height: var(--input-height);
  border: 1px solid var(--gray-color);
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

textarea {
  position: relative;
  width: 100%;
  height: calc(var(--input-height) * 4);
  border: 1px solid var(--gray-color);
  border-radius: 3px;
  padding: 10px;
  resize: none;
}

/* Tablet ( 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  :root {
    --divider-gap: 120px;

    --f16: 15px;
    --f20: 18px;
    --f28: px;
    --f42: 36px;
    --f55: 45px;
  }
}

@media all and (max-width: 767px) {
  :root {
    --divider-gap: 60px;

    --input-height: 50px;

    --font-size-default: 15px;
    --f16: 12px;
    --f20: 15px;
    --f24: 19px;
    --f28: 20px;
    --f42: 25px;
    --f55: 35px;
  }
}
