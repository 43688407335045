@tailwind base;
@tailwind components;
@tailwind utilities;

.on {
  display: block !important;
  animation: gnb-effect 0.3s ease-in-out;
}

@keyframes gnb-effect {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.line {
  position: relative;
  width: 100%;
  height: 1px;
  border: 1px solid #e7e7e7;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 500px;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
